import { useEffect, useState } from "react";
import { FiSearch, FiMenu, FiX } from "react-icons/fi";
import { Drawer, Collapse } from '@mantine/core';
import { LoginModal } from "../modal";
import Link from "next/link";
import { useRouter } from "next/router";
import { language } from "@/config/site";
import { getCookie } from "cookies-next";
import { AiOutlineRight } from "react-icons/ai";
import { ProfilePicture } from "./image";
import { _postURL } from "../../pages/api";
import _ from "lodash";
import moment from "moment";
import Redux, { updatePath } from "@/action";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import { Autoplay } from "swiper";
import { pageLink } from "../../config/site";
import { connect } from "react-redux";

const drawerNavigationObject = [
    {
        menu: 'home',
        navigate: pageLink.home.homepage,
    },
    {
        menu: 'news',
        navigate: pageLink.news.homepage,
    },
    {
        menu: 'brands',
        navigate: pageLink.vehicle.homepage,
    },
    {
        menu: 'dealer',
        navigate: pageLink.dealer.homepage,
    },
]

const fuelColor = ['#F9C834', '#179E6C', '#CCCCCC', '#FF2E55', '#222222', '#3C9FF2', '#222222', '#3C9FF2']

const HeaderDrawer = (props) => {
    const { opened, setOpened, t } = props
    const { store, dispatch } = Redux();
    const [loginOpened, setLoginOpened] = useState(false)
    const [languageCollapse, setLanguageCollapse] = useState(false)
    const router = useRouter()
    const [loading, setLoading] = useState(store?.path?.dataFuelPrice ? false : true)

    let userCookies;
    userCookies = getCookie("user") && JSON.parse(getCookie("user"));

    const _getAllFuelPrice = async() => {
        try {
            let req = {};
            let result = await _postURL("/api/fuel/getAllFuelPrice", {...req});
            if(result?.data){
                dispatch(updatePath({ key: "dataFuelPrice", path: result?.data }));
            }
        } catch(error) {
            console.log(error);
        }
    }

    useEffect(()=>{
        let isMounted = true;
        const fetchData = async() => {
            try {
                if (isMounted) {
                    if(_.isEmpty(store?.path?.dataFuelPrice)){
                        await _getAllFuelPrice()
                    }
                }
            } catch(error) {
                console.log(error)
            } finally {
                if (isMounted) { // Check if the component is still mounted
                    setLoading(false);
                }
            }
        }
        
        fetchData();
        return () => {
            isMounted = false;
        };
    },[])

    return (
        <>
            <Drawer
                opened={opened}
                onClose={()=>{setOpened(false)}}
                withCloseButton={false}
                position={"right"}
                overlayOpacity={0.5}
                overlayBlur={4}
                size={"30.7rem"}
                classNames={{
                    body: "drawer_body"
                }}
            >
                <div className="drawer">
                    <div onClick={()=>{setOpened(false)}} className="drawer_closeBox">
                        <FiX className='drawer_closeButton'/>
                        {/* <div className='drawer_closeButton'>
                            <img className='w-100 fontColor_redHover' src="/assets/images/close_red_icon.svg" />
                        </div> */}
                        <p className="fontSize_16 drawer_closeText">
                            {t('close')}
                        </p>
                    </div>
                    <div className="drawerMenu">
                        <div className="drawerMenu_list">
                            {
                                _.reduce(drawerNavigationObject, (result, value, key)=>{
                                    result.push(
                                        <Link key={key} href={value.navigate}>
                                            <div className="drawerMenu_item">
                                                <p className="fontSize_16 fontWeight_semiBold drawerMenu_itemText">
                                                    {t(value.menu)}
                                                </p>
                                                <div>

                                                </div>
                                            </div>
                                        </Link>
                                    )
                                    return result
                                },[])
                            }
                            <div className="drawerMenu_languageItemOuter">
                                <div onClick={()=>{setLanguageCollapse(!languageCollapse)}} className="drawerMenu_languageItem">
                                    <p className="fontSize_16 fontWeight_semiBold drawerMenu_itemText">
                                        {t('language')}
                                    </p>
                                    <div className="drawerMenu_languageItemRight">
                                        <p className="fontSize_14 drawerMenu_languageItemRightText">
                                            {language[router.locale].label}
                                        </p>
                                        {languageCollapse ?
                                            <div className='expand_icon'>
                                                <img className='w-100' src="/assets/images/expand_up_icon.svg" />
                                            </div>
                                            :
                                            <div className='expand_icon'>
                                                <img className='w-100' src="/assets/images/expand_right_icon.svg" />
                                            </div>
                                        }
                                    </div>
                                </div>
                                <Collapse in={languageCollapse}>
                                    {
                                        _.reduce(language, (result, value, key) => {
                                            result.push(
                                                <Link key={key} href={'/'} locale={language[key].value}>
                                                    <div className="drawerMenu_languageItemListRow cursorPointer">
                                                        <p className="fontSize_14 drawerMenu_languageItemText">
                                                            {language[key].label}
                                                        </p>
                                                        { 
                                                            router.locale == language[key].value &&
                                                            <div className='expand_tick_icon'>
                                                                <img className='w-100' src="/assets/images/tick_icon.svg" />
                                                            </div>
                                                        }
                                                    </div>
                                                </Link>
                                            )
                                            return result
                                        },[])
                                    }
                                </Collapse>
                            </div>
                        </div>
                        <div className="drawerMedia">
                            <p className="fontSize_16">
                                {t('follow_us')}
                            </p>
                            <div className="drawerMedia_list">
                                <a href={router.locale == "en" ? "https://www.facebook.com/profile.php?id=61554207628409" : "https://www.facebook.com/keyauto.my"} target="_blank" className="media_icon" rel="noopener noreferrer">
                                    <img className='w-100' src="/assets/images/facebook_icon.svg" />
                                </a>
                                <a href="https://www.instagram.com/keyauto.my/" target="_blank" className="media_icon" rel="noopener noreferrer">
                                    <img className='w-100' src="/assets/images/instagram_icon.png" />
                                </a>
                                <a href="https://youtube.com/@KeyautoMyPlus" target="_blank" className="media_icon" rel="noopener noreferrer">
                                    <img className='w-100' src="/assets/images/youtube_icon.svg" />
                                </a>
                                <a href="https://www.xiaohongshu.com/user/profile/6262ca2f00000000100093d7" target="_blank" className="media_icon" rel="noopener noreferrer">
                                    <img className='w-100' src="/assets/images/xhs_icon.png" />
                                </a>
                                <a href="https://www.tiktok.com/@keyauto.my" target="_blank" className="media_icon" rel="noopener noreferrer">
                                    <img className='w-100' src="/assets/images/tiktok_icon.png" />
                                </a>
                            </div>
                        </div>
                        {
                            !_.isEmpty(store?.path?.dataFuelPrice) && 
                            <div className="drawer_fuelPrice">
                                <p className="fontSize_16 fontWeight_bold drawer_fuelPrice_text">
                                    {t("fuel_price")}
                                </p>
                                {
                                    _.reduce(store?.path?.dataFuelPrice, (result, value, key) =>{
                                        if(key == 0){
                                            result.push(
                                                <div key={`state-${key}`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin:'20px 0 10px 0'}}>
                                                    <div style={{ height: '1px', width: '20px', borderTop: '1px solid #939393', marginRight: '4px'}}></div>
                                                    <p className="fontSize_16 fontWeight_semiBold fontColor_grey2">
                                                        Peninsular MY
                                                    </p>
                                                    <div style={{ height: '1px', width: '20px', borderTop: '1px solid #939393', marginLeft: '4px'}}></div>
                                                </div>
                                            )
                                            result.push(
                                                <div key={`type-${key}`}>
                                                    <p className="fontSize_16 fontWeight_semiBold">
                                                        {value['type']}
                                                    </p>
                                                </div>
                                            )
                                        }
                                        if(key == 4){
                                            result.push(
                                                <div key={`type-${key}`} style={{ marginTop: '20px'}}>
                                                    <p className="fontSize_16 fontWeight_semiBold">
                                                        {value['type']}
                                                    </p>
                                                </div>
                                            )
                                        }
                                        if(key == 6){
                                            result.push(
                                                <div key={`state-${key}`} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', margin:'20px 0 10px 0'}}>
                                                    <div style={{ height: '1px', width: '20px', borderTop: '1px solid #939393', marginRight: '4px'}}></div>
                                                    <p className="fontSize_16 fontWeight_semiBold fontColor_grey2">
                                                        East MY
                                                    </p>
                                                    <div style={{ height: '1px', width: '20px', borderTop: '1px solid #939393', marginLeft: '4px'}}></div>
                                                </div>
                                            )
                                            result.push(
                                                <div key={`type-${key}`}>
                                                    <p className="fontSize_16 fontWeight_semiBold">
                                                        {value['type']}
                                                    </p>
                                                </div>
                                            )
                                        }
                                        result.push(
                                            <div key={key} className="drawer_fuelPrice_item">
                                                <div className="drawer_fuelPrice_item_left">
                                                    <div className="drawer_fuelPrice_item_dot" style={{backgroundColor: fuelColor[(value?.fuel_price_id - 1)]}}>
                                                    
                                                    </div>
                                                    <p className="fontSize_14">
                                                        {value.name}
                                                    </p>
                                                </div>
                                                <p className="fontSize_14">
                                                    {value.price}
                                                </p>
                                            </div>
                                        )
                                        return result
                                    },[])
                                }
                                {/* {
                                    _.reduce(_.groupBy(store?.path?.dataFuelPrice, 'type'), (result, value, key)=>{
                                        result.push(
                                            <div key={key} className="drawer_fuelPrice_section">
                                                <p className="fontSize_16 fontWeight_semiBold">
                                                    {key}
                                                </p>
                                                <div>
                                                    {
                                                        _.reduce(value,( result2, value2, key2 )=>{
                                                            result2.push(
                                                                <div key={`${key}-${key2}`} className="drawer_fuelPrice_item">
                                                                    <div className="drawer_fuelPrice_item_left">
                                                                        <div className="drawer_fuelPrice_item_dot" style={{backgroundColor: fuelColor[(value2?.fuel_price_id - 1)]}}>
                                                                        
                                                                        </div>
                                                                        <p className="fontSize_14">
                                                                            {value2.name}
                                                                        </p>
                                                                    </div>
                                                                    <p className="fontSize_14">
                                                                        {value2.price}
                                                                    </p>
                                                                </div>
                                                            )
                                                            return result2
                                                        },[])
                                                    }
                                                </div>
                                            </div>
                                        )
                                        return result
                                    },[])
                                } */}
                                <div className="drawer_fuelPrice_date_position">
                                    <p className="fontSize_12 fontColor_grey">
                                        {`${t('last_updated')} ${moment(store?.path?.dataFuelPrice?.[0]?.updated_time, 'YYYY-MM-DD HH:mm:ss').format('MMM DD, yyyy')}`}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="drawerBottomOuter">
                        <div className="drawerBottomLineOuter">
                            <div className="dividingLine" />
                        </div>
                        {
                            userCookies ?
                            <>
                                <div className='drawerBottom_profile containerPadding'>
                                    <ProfilePicture 
                                        imagePath={!_.isEmpty(userCookies?.profile_image) ? process.env.NEXT_PUBLIC_FS_URL + userCookies?.profile_image : undefined}
                                        size={5.7}
                                    />
                                    <div>
                                        <p className='fontSize_16 fontWeight_semiBold drawerBottom_profileText'>
                                            {userCookies?.full_name}
                                        </p>
                                        <Link href={"/profile"}>
                                            <div className="drawerBottom_profileImage_viewProfile cursorPointer">
                                                <p className='fontSize_14 fontWeight_medium fontColor_red'>
                                                    {t('view_profile')} 
                                                </p>
                                                <AiOutlineRight className="drawerBottom_profileImage_viewProfile_icon fontColor_red" />
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </>
                            :
                            <div onClick={()=>{setLoginOpened(true)}} className="drawerBottom">
                                <div className="drawerBottom_button primaryButtonBackground">
                                    <p className="fontSize_14 fontWeight_bold fontColor_white">
                                        {t('sign_in')}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Drawer>
            <LoginModal 
                opened={loginOpened}
                setOpened={setLoginOpened}
                t={t}
            />
        </>
    )
}

const CustomHeader = (props) => {
    const { t, data = [], isLoadedInFlutterApp = false, subTab = null } = props
    const [opened, setOpened] = useState(false);
    const router = useRouter();
    const queryParams = new URLSearchParams(router.asPath.split('?')[1]);
    const isWebview = queryParams.get('isWebview') == "true" ? true : false;
    return (
        <>
            <div className='header' style={(isLoadedInFlutterApp == true || isWebview == true) ? {paddingBottom: 0, paddingTop: 0} : subTab != null ? {paddingBottom: 0} : {}}>
                {
                    (isLoadedInFlutterApp == true || isWebview == true) 
                    ? <></>
                    :
                    <div className="header_row containerPadding">
                        <Link href={'/'}>
                            <div className='header_logo mt_4 d_flex cursorPointer'>
                                <img className='w-100' src="/assets/images/logo.png" />
                            </div>
                        </Link>
                        <Link href={'/search'}>
                            <div className="header_search">
                                <div className="header_searchInner">
                                    <div className="header_searchInner_icon cursorPointer">
                                        <FiSearch className='headerIcon_searchInput' />
                                    </div>
                                    <div className="header_searchInner_text">
                                        {
                                            !_.isEmpty(data) &&
                                            <Swiper
                                                slidesPerView={1}
                                                direction={'vertical'}
                                                modules={[Autoplay]}
                                                className="mySwiper"
                                                loop={true}
                                                autoplay={{
                                                    delay: 5000,
                                                    disableOnInteraction: false
                                                }}
                                            >
                                                {
                                                    _.reduce(data, (result, value, key)=>{
                                                        result.push(
                                                            <SwiperSlide key={key}>
                                                                <div className="header_searchInner_text_box">
                                                                    <p className="fontSize_14 fontColor_grey4 fontWeight_medium header_searchInner_text_box_text">{value?.name}</p>
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                        return result
                                                    },[])
                                                }
                                            </Swiper>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Link>
                        <div className='header_iconSingleList mt_4'>
                            <div className="cursorPointer " onClick={()=>{setOpened(true)}}>
                                <FiMenu className='headerIcon'
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    subTab
                }
            </div>
            <HeaderDrawer 
                setOpened={setOpened}
                opened={opened}
                t={t}
            />
        </>
    )
}

const CustomHeaderBack = (props) => {
    const { searchVisible = true, menuVisible = true, t, isLoadedInFlutterApp = false, subTab = null }= props
    const [opened, setOpened] = useState(false);
    const router = useRouter()
    const queryParams = new URLSearchParams(router.asPath.split('?')[1]);
    const isWebview = queryParams.get('isWebview') == "true" ? true : false;

    return (
        <>
            <div className='headerBack' style={(isLoadedInFlutterApp == true || isWebview == true) ? {paddingBottom: 0, paddingTop: 0} : subTab != null ? {paddingBottom: 0} : {}}>
                {
                    (isLoadedInFlutterApp == true || isWebview == true) 
                    ? <></>
                    :
                    <div className="headerBack_row containerPadding">
                        <div 
                            // onClick={()=>{ router.back() }} 
                            onClick={async () => {
                                if (typeof window !== 'undefined' && +window?.history?.state?.idx > 0) {
                                    router.back()
                                } else {
                                    router.push("/")
                                }
                            }}
                            className="headerBack_backIconOuter cursorPointer"
                        >
                            <div className="headerBack_backIcon">
                                <img className='w-100' src="/assets/images/headerBack_icon.svg" />
                            </div>
                        </div>
                        <Link href={"/"}>
                            <div className='header_logo cursorPointer'>
                                <img className='w-100' src="/assets/images/logo.png" />
                            </div>
                        </Link>
                        <div className='header_iconList'>
                            { 
                                searchVisible &&
                                <Link href={'/search'}>
                                    <div className="cursorPointer">
                                        <FiSearch className='headerIcon' />
                                    </div>
                                </Link>
                            }
                            {
                                menuVisible &&
                                <div className="cursorPointer" onClick={()=>{setOpened(true)}}>
                                    <FiMenu className='headerIcon'/>
                                </div>
                            }
                        </div>
                    </div>
                }
                {
                    subTab
                }
            </div>
            <HeaderDrawer 
                setOpened={setOpened}
                opened={opened}
                t={t}
            />
        </>
    )
}

const CustomHeaderBackTitle = (props) =>{
    const { title, showSearch = true, showMenu = true, t, showTitle = true, routerBack = false, isLoadedInFlutterApp = false, subTab = null } = props
    const [opened, setOpened] = useState(false);
    const router = useRouter()
    const queryParams = new URLSearchParams(router.asPath.split('?')[1]);
    const isWebview = queryParams.get('isWebview') == "true" ? true : false;
    return (
        <>
            <div className='headerBack' style={(isLoadedInFlutterApp == true || isWebview == true) ? {paddingBottom: 0, paddingTop: 0} : subTab != null ? {paddingBottom: 0} : {}}>
                {
                    (isLoadedInFlutterApp == true || isWebview == true) 
                    ? <></>
                    :
                    <div className="headerBack_row containerPadding">
                        <div
                            // onClick={()=>{ router.back() }} 
                            onClick={async () => {
                                if (typeof window !== 'undefined' && +window?.history?.state?.idx > 0) {
                                    if(routerBack == false){
                                        router.back()
                                    }
                                    else{
                                        router.push(routerBack)
                                    }
                                } else {
                                    router.push("/")
                                }
                            }}
                            className="headerBackTitle_backIconOuter cursorPointer"
                        >
                            <div className="headerBack_backIcon">
                                <img className='w-100' src="/assets/images/headerBack_icon.svg" />
                            </div>
                        </div>
                        {
                            showTitle &&
                            <p className="fontSize_18 fontWeight_bold paragraph_max_line_1" style={{ flex: 1 }}>
                                {title}
                            </p>
                        }
                        <div className='header_iconList'>
                        {
                            showSearch &&
                            <Link href={'/search'}>
                                <div className="cursorPointer">
                                    <FiSearch className='headerIcon' />
                                </div>
                            </Link>
                        }
                        {
                            showMenu &&
                            <div className="cursorPointer" onClick={()=>{setOpened(true)}}>
                                <FiMenu className='headerIcon'/>
                            </div>
                        }
                        </div>
                    </div>
                }
                {
                    subTab
                }
            </div>
            <HeaderDrawer 
                setOpened={setOpened}
                opened={opened}
                t={t}
            />
        </>
    )
}

const mapStateToProps = (state) => {// Log the Redux state
    return {
        isLoadedInFlutterApp: state?.path?.isLoadedInFlutterApp ?? false, // Assuming 'isLoadedInFlutterApp' is the Redux state you want to access
    };
};

export const Header = connect(mapStateToProps)(CustomHeader);
export const HeaderBack = connect(mapStateToProps)(CustomHeaderBack);
export const HeaderBackTitle = connect(mapStateToProps)(CustomHeaderBackTitle);