import _ from "lodash"
import Link from "next/link"
import { colorBlueActiveCode, pageLink } from "../../config/site"
import { ExpendMore, RankingNum } from "../common/icon"
import { SkeletonSpeedTestRow } from "../common/skeleton"
import { CustomLink } from "../app/customLink"

export const RankingItem = (props) =>{
    const { data, extraClass = "" } = props
    return (
        <CustomLink 
            href={`${pageLink.vehicle.vehicleMidUrl}${data.brand_custom_url}/${data.model_custom_url}/${data.custom_url}`}
        >
            <div className={`rankingPage_item cursorPointer ${extraClass}`}>
                <div className='rankingPage_item_ranking'>
                    {
                        data.ranking == 1 || data.ranking == 2 || data.ranking == 3 ?
                            <RankingNum num={data.ranking} />
                        :
                        <p className='fontSize_14 fontColor_grey3'>
                            {data.ranking}
                        </p>
                    }
                </div>
                <div className='rankingPage_item_image'>
                    <img 
                        loading="lazy"
                        src={`${!_.isEmpty(data.image_path) ? process.env.NEXT_PUBLIC_FS_URL + data.image_path : "/assets/images/empty/empty_vehicle.png"}`}
                    />
                </div>
                <div className='rankingPage_item_content'>
                    <p className='fontSize_14 fontWeight_semiBold'>
                        {data.brand_name}
                    </p>
                    <p className='fontSize_12 fontWeight_semiBold'>
                        {data.variant_name}
                    </p>
                </div>
                <div className='rankingPage_item_sec'>
                    <p className='fontSize_14 fontWeight_bold fontColor_red fontStyle_italic'>
                        {data.result} sec
                    </p>
                </div>
            </div>
        </CustomLink>
    )
}

export const RankingList = (props) => {
    const { t,  data = [], isViewMore = false, loadSkeletonItemNum = 5, loading = false} = props
    return (
        <div className='rankingList_component containerPadding'>
            <div className='rankingList_component_section'>
                <div className='rankingList_component_section_header'>
                    <div className='rankingList_component_section_header_logo' />
                    <p className='fontSize_24 fontWeight_extraBold fontStyle_italic rankingList_component_section_header_rightText'>
                        SPEED TEST
                    </p>
                </div>
                {
                    loading == false ?
                    <>
                        {
                            _.reduce(data, (result, value, key)=>{
                                result.push(
                                    <RankingItem key={key} data={value} />
                                )
                                return result
                            },[])
                        }
                    </>
                    :
                    <>
                        {
                            _.times(loadSkeletonItemNum, (key)=>(
                                <SkeletonSpeedTestRow key={key} />
                            ))
                        }
                    </>
                }
                {
                    isViewMore == true &&
                    <div className='rankingList_component_section_footer_viewMore'>
                        <CustomLink href={pageLink.ranking.homepage}>
                            <div className="viewMore cursorPointer">
                                <p className='fontSize_14 fontWeight_semiBold fontColor_blue'>
                                    {t('view_more')}
                                </p>
                                <div className="viewMore_icon">
                                    <ExpendMore color={colorBlueActiveCode} />
                                </div>
                            </div>
                        </CustomLink>
                    </div>
                }
            </div>
        </div>
    )
}