export const mobileViewWidth = 1024;
// export const responsiveMobile = 768; // <= 1024 mobile, > 1024 desktop
export const cookieExpireMaxAge = "2592000" // 1 month 60 * 60 * 24 * 30
export const colorRedActiveCode = "#E91C21"
export const colorGreyInActiveCode = "#AAAAAA"
export const colorBlueActiveCode = "#0094FF"
export const pageLimit = 10;
export const newsTestDriveId = {
    en: 10,
    zh: 7
}
export const calculatorDefaultValue = {
    price: 100000,
    downPaymentVal: 10,
    interestRateVal: 3,
    loanPeriodVal: 7,
    engineCapacity: 1500
}

export const language = {
    en: {
        label: "English",
        value: "en",
    },
    zh: {
        label: "中文",
        value: "zh"
    }
}

export const countryState = [
    {
        label: "All",
        value: "all",
    },
    {
        label: "Kuala Lumpur",
        value: "state-kuala-lumpur",
    },
    {
        label: "Pulau Pinang",
        value: "state-pulau-pinang",
    },
    {
        label: "Selangor",
        value: "state-selangor",
    },
    {
        label: "Perak",
        value: "state-perak",
    },
    {
        label: "Johor",
        value: "state-johor",
    },
    {
        label: "Melaka",
        value: "state-melaka",
    },
    {
        label: "Sabah",
        value: "state-sabah",
    },
    {
        label: "Pahang",
        value: "state-pahang",
    },
    {
        label: "Kedah",
        value: "state-kedah",
    },
    {
        label: "Terengganu",
        value: "state-terengganu",
    },
    {
        label: "Sarawak",
        value: "state-sarawak",
    },
    {
        label: "Negeri Sembilan",
        value: "state-negeri-sembilan",
    },
]

export const priceOption = [
    {
        label: "0-40k",
        value: { min_price: 1000, max_price: 40000, label: "0-40k" },
    },
    {
        label: "40-60k",
        value: { min_price: 40000, max_price: 60000, label: "40-60k" }
    },
    {
        label: "60-90k",
        value: { min_price: 60000, max_price: 90000, label: "60-90k" }
    },
    {
        label: "90-120k",
        value: { min_price: 90000, max_price: 120000, label: "90-120k" }
    },
    {
        label: "120-150k",
        value: { min_price: 120000, max_price: 150000, label: "120-150k" }
    },
    {
        label: "150-200k",
        value: { min_price: 150000, max_price: 200000, label: "150-200k" }
    },
    {
        label: "200-300k",
        value: { min_price: 200000, max_price: 300000, label: "200-300k" }
    },
    {
        label: "300-400k",
        value: { min_price: 300000, max_price: 400000, label: "300-400k" }
    },
    {
        label: "400-600k",
        value: { min_price: 400000, max_price: 600000, label: "400-600k" }
    },
    {
        label: "600-3000k",
        value: { min_price: 600000, max_price: 3000000, label: "600-3000k" }
    },
]

export const mileageOption = [
    {
        label: "1 - 50,000 km",
        // value: { min_price: 1, max_price: 50000, label: "1 - 50,000 km" },
        value: "1 - 50,000 km"
    },
    {
        label: "50,001 - 100,000 km",
        // value: { min_price: 50001, max_price: 100000, label: "50,001 - 100,000 km" },
        value: "50,001 - 100,000 km"
    },
    {
        label: "100,001 - 150,000 km",
        // value: { min_price: 100001, max_price: 150000, label: "100,001 - 150,000 km" },
        value: "100,001 - 150,000 km"
    },
    {
        label: "150,001 - 200,000 km",
        // value: { min_price: 150001, max_price: 200000, label: "150,001 - 200,000 km" },
        value: "150,001 - 200,000 km"
    },
    {
        label: "200,001 - 250,000 km",
        // value: { min_price: 200001, max_price: 250000, label: "200,001 - 250,000 km" },
        value: "200,001 - 250,000 km"
    },
    {
        label: "250,001 - 300,000 km",
        // value: { min_price: 250001, max_price: 300000, label: "250,001 - 300,000 km" },
        value: "250,001 - 300,000 km"
    },
    {
        label: "300,001 - 350,000 km",
        // value: { min_price: 300001, max_price: 350000, label: "300,001 - 350,000 km" },
        value: "300,001 - 350,000 km"
    },
    {
        label: "350,001 km and above",
        // value: { min_price: 350001, max_price: 999999, label: "350,001 km and above" },
        value: "350,001 km and above"
    },
]

export const electricOutput = {
    1: {label: "ALL"},
    2: {label: "AC"},
    3: {label: "DC"}
}


export const electricPlugType = {
    // 1: {
    //     1: {label: "Tesla", imagePath: '/assets/images/icon/ev_plug_tesla.svg'}
    // },
    2: {
        // 2: {label: "J1772(Type 1)", imagePath: '/assets/images/icon/ev_plug_j1772.svg'},
        3: {label: "Mennekes (Type 2)", imagePath: '/assets/images/icon/ev_plug_mennekes.svg'},
        // 7: {label: "GB/T(AC)", imagePath: '/assets/images/icon/ev_plug_gbt_ac.svg'}
    },
    3: {
        4: {label: "CCS 1", imagePath: '/assets/images/icon/ev_plug_ccs1.svg'},
        5: {label: "CCS 2", imagePath: '/assets/images/icon/ev_plug_ccs2.svg'},
        // 6: {label: "CHAdeMO", imagePath: '/assets/images/icon/ev_plug_chademo.svg'},
        // 8: {label: "GB/T(DC)", imagePath: '/assets/images/icon/ev_plug_gbt_ac.svg'}
    }
}

export const restrictedType = {
    1: {label: "All"},
    2: {label: "Residents Only"},
    3: {label: "Guest Only"},
    4: {label: "Customer Only"}
}

/**
 * @constant {Object}
 * @global
 */
export const pageLinkSameUrl = {
    calculator: {
        vehicleLoad: "vehicle-loan",
        vehicleInsurance: "vehicle-insurance",
        vehicleRoadTax: "vehicle-road-tax"
    }
}
export const pageLink = {
    home: {
        homepage: '/'
    },
    authentication: {
        login: '/login',
        signup: '/signup'
    },
    user: {
        profile: '/user/profile'
    },
    news: {
        homepage: '/news',
        newsMidUrl: '/news/'
    },
    author: {
        authorMidUrl: '/author/'
    },
    vehicle: {
        homepage: '/vehicle',
        vehicleMidUrl: '/vehicle/', // brands ,brands/model, brands/model/spec, brands/model/gallery, brands/model/spec/gallery, brands/model/review, brands/model/write
        filter: '/vehicle/filter',
        filterResult: '/vehicle/filter/result',
        vehicleSpec: '/spec',
        vehicleGallery: '/gallery',
        vehicleReview: '/review',
        vehicleVideo: '/video'
    },
    general: {
        aboutUs: '/about-us',
        pageNotFound: '/404',
        pageServerError: '/500',
        pageUnauthorisedError: '/401',
        privacyPolicy: '/privacypolicy/',
        aboutUs: '/about_us/'
    },
    compare: {
        homepage: '/compare',
        compareMidUrl : '/compare/'
    },
    ranking: {
        homepage: '/ranking'
    },
    calculator: {
        homepage: '/calculator',
        type: '/calculator/[...type]',
        vehicleLoad: '/calculator/' + pageLinkSameUrl.calculator.vehicleLoad,
        vehicleInsurance: '/calculator/' + pageLinkSameUrl.calculator.vehicleInsurance,
        vehicleRoadTax: '/calculator/' + pageLinkSameUrl.calculator.vehicleRoadTax
    },
    dealer: {
        homepage: '/dealer',
        dealerMidUrl: '/dealer/'
    },
    profile: {
        homepage: '/profile',
        edit: '/profile/edit',
        setting: '/profile/setting',
        vehicleList: '/profile/vehicle',
        vehicleDetail: '/profile/vehicle/',
        voucher: '/profile/voucher',
        voucherDetail: '/profile/voucher/',
        myRedemptionDetail: '/profile/myredemption/',
        accountLog: '/profile/pointhistory',
        accountLogDetail: '/profile/pointhistory/'
    },
    tyre: {
        homepage: '/tyre',
        tyreMidUrl: '/tyre/',
        filter: '/tyre/filter',
        filterResult: '/tyre/filter/result',
        tyreSpec: '/spec',
        tyreReview: '/review',
    },
    map: {
        evStation: '/map/ev_station',
        evStationDetail: '/map/ev_station/'
    }
}

export const placementArea = {
    homePage_popUp: 1,
    homePage_afterBrands: 2,
    newsDetailPage_beforeGallery: 3,
    gallerModal_3rdImage: 4,
    vehicleModelDetailPage_beforeDealer: 5,
    compareResultPage_afterTyre: 6,
    vehicleMdeolDetailPage_afterOwnerShip: 7,
    vehiclePage_beforeBrandList: 8,
    newsDetailPage_beforeArticle: 9,
    tyre_homePage: 10,
    tyreModel_detailPage: 11
}

export const videoEmbed = {
    youtube: "https://www.youtube.com/embed/",
    tiktok: "https://www.tiktok.com/embed/v2/",
}

export const compareResultGridRow = 188

export const googleMapSetting = {
    defaultLocation: {lat: 3.099140, lng: 101.700953 }
}

export const standardColor = {
    placeHolderColor: '#B7B7B7' 
}

export const missionType = {
    addVehicleMission: 1
}

export const functionConfig = {
    loginPageReferralCode: false,
    profilePage: true,
}

export const qrCode = "https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=";

export const billingPaymentMethod = {
    0: {label: ""},
    1: {label: "App payment"},
    2: {label: "Cash"},
    3: {label: "Bank Card"},
    4: {label: "Qr"}
}

export const billingType = {
    0: {label: ""},
    1: {label: "Quotation"},
    2: {label: "Invoice"},
    3: {label: "Receipt"},
}

const Site = () => { };
export default Site;